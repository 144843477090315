import React from "react"

import Layout from "../components/layout"

const ComingPage = () => (
  <Layout>
    <section className="content__body--coming">
    </section>
  </Layout>
)

export default ComingPage

